// src/contexts/PipelineContext.jsx v1.0.0

import React, { createContext, useContext, useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { useFirebase } from './FirebaseContext';
import { useAuth } from './AuthContext';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { processPipelineData, getCurrentAndPreviousYear, processWinProbabilityHeatmap } from './utils/pipelineProcessing';
import { formatCurrency, formatPercentage, formatDate, formatNumber } from '../components/utils/formatters';
import { useChartStyles } from '../components/hooks/useChartStyles';
import { calculateTruepipeProbability } from './utils/truepipeProbability';

const PipelineContext = createContext();

export const usePipeline = () => useContext(PipelineContext);

export const PipelineProvider = ({ children }) => {
  const [pipelineData, setPipelineData] = useState(null);
  const [winProbabilityHeatmap, setWinProbabilityHeatmap] = useState([]);
  const [forecastLines, setForecastLines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const chartStyles = useChartStyles();
  const { db } = useFirebase();
  const { currentUser } = useAuth();

  // Add this truncate function
  const truncateString = (str, num) => {
    if (str.length <= num) return str;
    return str.slice(0, num) + '...';
  };

  // Update this function to use truncateString
  const getDealSizeScatterPlotTooltip = (node) => ({
    title: truncateString(node.data.dealName, 20), // Truncate the deal name to 20 characters
    items: [
      { label: 'Amount', value: node.data.y, formatType: 'currency' },
      { label: 'Days Open', value: node.data.x, formatType: 'number' },
      { 
        label: 'Probability', 
        value: node.data.probability * 100, // Multiply by 100 to convert decimal to percentage
        formatType: 'percentage' 
      },
    ],
  });

  // Calculate forecast values based on deals and truepipe probability
  const calculateForecastLines = (deals) => {
    const now = new Date();
    const historicalDeals = [];
    const forecastDeals = [];

    // 1. Split deals into historical and forecast
    deals.forEach(deal => {
      const closeDate = new Date(deal.closedate);
      if (closeDate <= now) {
        historicalDeals.push({
          ...deal,
          closedate: closeDate
        });
      } else {
        forecastDeals.push({
          ...deal,
          closedate: closeDate
        });
      }
    });

    // 2. Process historical deals (last 12 months)
    const twelveMonthsAgo = new Date();
    twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);
    
    const historicalData = historicalDeals
      .filter(deal => deal.closedate >= twelveMonthsAgo)
      .reduce((acc, deal) => {
        const monthKey = `${deal.closedate.getFullYear()}-${String(deal.closedate.getMonth() + 1).padStart(2, '0')}`;
        if (!acc[monthKey]) {
          acc[monthKey] = { amount: 0, count: 0 };
        }
        acc[monthKey].amount += parseFloat(deal.amount) || 0;
        acc[monthKey].count++;
        return acc;
      }, {});

    // 3. Process forecast deals (next 6 months)
    const sixMonthsAhead = new Date();
    sixMonthsAhead.setMonth(sixMonthsAhead.getMonth() + 6);

    const forecastData = forecastDeals
      .filter(deal => deal.closedate <= sixMonthsAhead)
      .reduce((acc, deal) => {
        const monthKey = `${deal.closedate.getFullYear()}-${String(deal.closedate.getMonth() + 1).padStart(2, '0')}`;
        if (!acc[monthKey]) {
          acc[monthKey] = { bestCase: 0, worstCase: 0, expected: 0, count: 0 };
        }
        
        const amount = parseFloat(deal.amount) || 0;
        const probability = calculateTruepipeProbability(deal);
        
        // Calculate different scenarios
        acc[monthKey].bestCase += probability > 0.7 ? amount : (amount * 0.5);
        acc[monthKey].worstCase += probability > 0.9 ? (amount * 0.8) : 0;
        acc[monthKey].expected += amount * probability;
        acc[monthKey].count++;
        
        return acc;
      }, {});

    return {
      historical: Object.entries(historicalData)
        .map(([month, data]) => ({
          month,
          value: data.amount,
          type: 'historical'
        }))
        .sort((a, b) => a.month.localeCompare(b.month)),
      forecast: Object.entries(forecastData)
        .map(([month, data]) => ({
          month,
          bestCase: data.bestCase,
          worstCase: data.worstCase,
          expected: data.expected,
          type: 'forecast'
        }))
        .sort((a, b) => a.month.localeCompare(b.month))
    };
  };

  useEffect(() => {
    if (!currentUser) {
      setPipelineData(null);
      setWinProbabilityHeatmap([]);
      setForecastLines([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    const dealsRef = collection(db, 'users', currentUser.uid, 'deals');
    const dealsQuery = query(dealsRef);

    const unsubscribe = onSnapshot(dealsQuery, 
      (snapshot) => {
        const deals = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('Raw deals from Firebase:', deals);
        const processedData = processPipelineData(deals);
        setPipelineData(processedData);
        setWinProbabilityHeatmap(processWinProbabilityHeatmap(deals));
        setForecastLines(calculateForecastLines(deals));
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching deals:', err);
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [db, currentUser]);

  const getDealValueOverTimeTooltip = (slice) => {
    const { currentYear, previousYear } = getCurrentAndPreviousYear();
    const currentYearPoint = slice.points.find(p => p.serieId === `${currentYear} Deal Value`);
    const previousYearPoint = slice.points.find(p => p.serieId === `${previousYear} Deal Value`);

    if (!currentYearPoint || !previousYearPoint) return null;

    const yearOverYearChange = currentYearPoint.data.y - previousYearPoint.data.y;
    const percentageChange = (yearOverYearChange / previousYearPoint.data.y);

    return {
      title: slice.points[0].data.x,
      items: [
        { label: `${currentYear}`, value: currentYearPoint.data.y, formatType: 'currency' },
        { label: `${previousYear}`, value: previousYearPoint.data.y, formatType: 'currency' },
      ],
      comparisonItem: {
        label: 'YoY Change',
        value: yearOverYearChange,
        percentage: percentageChange,
        formatType: 'currency',
      },
    };
  };

  const getDealTypeDistributionTooltip = (datum) => ({
    title: 'Deal Type',
    items: [
      { label: datum.label, value: datum.value, formatType: 'currency' },
    ],
    comparisonItem: {
      label: 'Percentage',
      value: datum.percentage,
      formatType: 'percentage',
    },
  });

  const getPipelineFunnelTooltip = (part) => {
    console.log('Funnel tooltip part:', JSON.stringify(part, null, 2)); // More detailed logging
    return {
      title: part.label,
      items: [
        { label: 'Value', value: part.value, formatType: 'currency' },
        { label: 'Deal Count', value: part.data.dealCount, formatType: 'number' },
      ],
    };
  };

  const getWinProbabilityHeatmapTooltip = (cell) => ({
    title: `${cell.yKey} - ${cell.xKey}`,
    items: [
      { label: 'Win Probability', value: cell.value, formatType: 'percentage' },
    ],
  });

  const value = {
    ...pipelineData,
    winProbabilityHeatmap,
    forecastLines,
    loading,
    error,
    formatCurrency,
    formatPercentage,
    formatDate,
    formatNumber,
    ...chartStyles,
    getDealValueOverTimeTooltip,
    getDealSizeScatterPlotTooltip,
    getDealTypeDistributionTooltip,
    getPipelineFunnelTooltip,
    getWinProbabilityHeatmapTooltip,
    getCurrentAndPreviousYear,
    theme,
    isMobile,
    truncateString,
  };

  return (
    <PipelineContext.Provider value={value}>
      {children}
    </PipelineContext.Provider>
  );
};
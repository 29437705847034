import React, { useContext } from 'react';
import { Box, Container } from '@mui/material';
import { useLocation } from 'react-router-dom';
import AppAppBarVC from './AppAppBarVC';
import Footer from '../global/Footer';
import { ColorModeContext } from '../../theme';

const Layout = ({ children }) => {
  const colorMode = useContext(ColorModeContext);
  const location = useLocation();

  const handleOpenSignupModal = () => {
    // Implement this function to open the signup modal
    console.log('Open signup modal');
  };

  const isHomePage = location.pathname === '/';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppAppBarVC
        mode={colorMode.mode}
        toggleColorMode={colorMode.toggleColorMode}
        onOpenSignupModal={handleOpenSignupModal}
        isHomePage={isHomePage}
      />
      <Container component="main" sx={{ flexGrow: 1, py: 4 }}>
        {children}
      </Container>
      <Footer />
    </Box>
  );
};

export default Layout;
// src/scenes/sales/insights.jsx

import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Tabs, 
  Tab,
  CircularProgress,
  Alert
} from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { OverviewProvider, useOverview } from '../../contexts/OverviewContext';
import { useDashboardStyles } from '../../components/hooks/useDashboardStyles';

// Import components
import InsightFilters from '../../components/sales/InsightsDashboard/InsightFilters';
import DetailedInsightView from '../../components/sales/InsightsDashboard/DetailedInsightView';
import InsightsTrends from '../../components/sales/InsightsDashboard/InsightsTrends';
import WatchlistDetailed from '../../components/sales/InsightsDashboard/WatchlistDetailed';
import AIRecommendations from '../../components/sales/InsightsDashboard/AIRecommendations';

const InsightsDashboardContent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedInsight, setSelectedInsight] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const { aiInsights, aiWatchlist, loading, error } = useOverview();
  const { layout, typography, components } = useDashboardStyles();

  const tabs = [
    { label: 'Watchlist', icon: <TrendingUpIcon /> },
    { label: 'All Insights', icon: <AssessmentIcon /> },
    { label: 'Trends', icon: <TimelineIcon /> }
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedInsight(null);
  };

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: 'calc(100vh - 100px)'
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">
          Error loading insights: {error}
        </Alert>
      </Box>
    );
  }

  if (!aiInsights || !aiWatchlist) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="info">
          No insights data available. Please make sure your HubSpot integration is properly configured.
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ ...layout.pageWrapper, pb: 6 }}>
      <Box sx={layout.headerContainer}>
        <Typography variant="h2" sx={typography.pageTitle}>
          AI Sales Insights
        </Typography>
      </Box>

      <Tabs 
        value={activeTab}
        onChange={handleTabChange}
        sx={components.tabs.root}
      >
        {tabs.map((tab, index) => (
          <Tab 
            key={index}
            icon={tab.icon}
            label={tab.label}
            sx={components.tabs.tab}
          />
        ))}
      </Tabs>

      <InsightFilters 
        open={showFilters}
        onClose={() => setShowFilters(false)}
      />

      <Box sx={layout.contentContainer}>
        {activeTab === 0 && (
          <WatchlistDetailed 
            watchlist={aiWatchlist}
          />
        )}

        {activeTab === 1 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AIRecommendations 
                insights={aiInsights}
                onInsightSelect={setSelectedInsight}
                selectedInsight={selectedInsight}
              />
            </Grid>
          </Grid>
        )}

        {activeTab === 2 && (
          <InsightsTrends 
            insights={aiInsights}
            watchlist={aiWatchlist}
          />
        )}
      </Box>
    </Box>
  );
};

// Wrap the dashboard with OverviewProvider
const InsightsDashboard = () => (
  <OverviewProvider>
    <InsightsDashboardContent />
  </OverviewProvider>
);

export default InsightsDashboard;

import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import BarChartIcon from '@mui/icons-material/BarChart';
import PsychologyIcon from '@mui/icons-material/Psychology';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import UpdateIcon from '@mui/icons-material/Update';

// Styled components
const FeatureCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: 0,
  height: '100%',
  backgroundColor: 'transparent',
  border: 'none',
  transition: 'none',
  '&:hover': {
    transform: 'none',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: '8px',
  backgroundColor: '#E6F7F1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
  '& svg': {
    color: '#059669',
    fontSize: '1.25rem',
  },
}));

// Feature list data
const features = [
  {
    name: 'Real-time MRR, ARR & Retention Tracking',
    description: 'Get instant visibility into your portfolio companies\' recurring revenue metrics.',
    icon: <BarChartIcon />,
  },
  {
    name: 'AI-Powered Forecasting',
    description: 'Leverage machine learning for accurate quarterly revenue predictions.',
    icon: <PsychologyIcon />,
  },
  {
    name: 'Sales Performance Analytics',
    description: 'Track rep performance and deal closure rates across your portfolio.',
    icon: <TrendingUpIcon />,
  },
  {
    name: 'Instant Updates',
    description: 'Receive real-time notifications on significant revenue changes and milestones.',
    icon: <UpdateIcon />,
  },
];

const FeaturesVC = () => {
  return (
    <Box 
      id="features" 
      sx={{ 
        py: { xs: 8, md: 10 },
        bgcolor: 'background.default',
      }}
    >
      <Container maxWidth="lg">
        {/* Section Header */}
        <Box sx={{ textAlign: 'center', mb: { xs: 6, md: 8 } }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 700,
              lineHeight: 1.2,
              mb: 2,
              color: 'text.primary',
            }}
          >
            Everything you need to monitor portfolio performance
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: '1rem', sm: '1.125rem' },
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto',
              lineHeight: 1.5,
            }}
          >
            We leverage AI to provide real-time MRR, ARR, rep performance, quarterly forecasting and closed/won predictions for all of your portfolio investments, in one easy to manage dashboard.
          </Typography>
        </Box>

        {/* Feature Cards Grid */}
        <Grid container spacing={4}>
          {features.map((feature) => (
            <Grid item xs={12} sm={6} lg={3} key={feature.name}>
              <FeatureCard>
                <IconWrapper>
                  {feature.icon}
                </IconWrapper>
                <Typography
                  variant="h6"
                  sx={{
                    mb: 1,
                    fontWeight: 600,
                    fontSize: '1.125rem',
                  }}
                >
                  {feature.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    lineHeight: 1.5,
                  }}
                >
                  {feature.description}
                </Typography>
              </FeatureCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FeaturesVC; 
import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import StorageIcon from '@mui/icons-material/Storage';
import BoltIcon from '@mui/icons-material/Bolt';
import ShowChartIcon from '@mui/icons-material/ShowChart';

const StepCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(4),
  height: '100%',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(0, 0, 0, 0.2)'
    : theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 4,
  overflow: 'hidden',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: theme.shadows[2],
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 40,
  height: 40,
  borderRadius: '12px',
  backgroundColor: '#E6F7F1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
  '& svg': {
    color: '#059669',
    fontSize: '1.25rem',
  },
}));

const StepNumber = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: -18,
  right: 0,
  fontSize: '8rem',
  fontWeight: 700,
  color: theme.palette.mode === 'dark' 
    ? 'rgba(255, 255, 255, 0.03)'
    : 'rgba(16, 185, 129, 0.05)',
  userSelect: 'none',
  lineHeight: 1,
}));

const steps = [
  {
    icon: <StorageIcon />,
    title: 'Data Integration',
    description: 'Our patented algorithm analyzes CRM data in real-time, ensuring you always have the latest information.',
  },
  {
    icon: <BoltIcon />,
    title: 'Instant Processing',
    description: 'Multiple CRM instances are processed simultaneously, providing a comprehensive view of your portfolio.',
  },
  {
    icon: <ShowChartIcon />,
    title: 'Unified Visualization',
    description: 'Access all your revenue and retention insights through a single, intuitive interface.',
  },
];

const HowItWorksVC = () => {
  return (
    <Box 
      id="how-it-works" 
      sx={{ 
        py: { xs: 8, md: 10 },
        bgcolor: 'background.default',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Decorative gradient background */}
      <Box
        sx={{
          position: 'absolute',
          inset: 0,
          zIndex: 0,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            right: '50%',
            bottom: 0,
            width: '200%',
            aspectRatio: '1/0.3',
            transform: 'translateX(-50%)',
            filter: 'blur(64px)',
            background: theme => theme.palette.mode === 'dark'
              ? 'linear-gradient(to bottom right, rgba(52, 211, 153, 0.05), transparent)'
              : 'linear-gradient(to bottom right, rgba(16, 185, 129, 0.1), transparent)',
            opacity: theme => theme.palette.mode === 'dark' ? 0.6 : 0.7,
          }}
        />
      </Box>

      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        {/* Section Header */}
        <Box sx={{ textAlign: 'center', mb: { xs: 6, md: 8 } }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 700,
              lineHeight: 1.2,
              mb: 2,
              color: 'text.primary',
            }}
          >
            How It Works
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: '1rem', sm: '1.125rem' },
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto',
              lineHeight: 1.5,
            }}
          >
            truepipe analyzes CRM data using a patented algorithm to provide real-time revenue and retention information. Our API and visualization framework is the only interface capable of displaying multiple CRM instances in one place at the same time.
          </Typography>
        </Box>

        {/* Steps Grid */}
        <Grid container spacing={{ xs: 4, md: 6 }}>
          {steps.map((step, index) => (
            <Grid item xs={12} md={4} key={step.title}>
              <StepCard>
                <StepNumber>{index + 1}</StepNumber>
                <Box sx={{ position: 'relative', zIndex: 1 }}>
                  <IconWrapper>
                    {step.icon}
                  </IconWrapper>
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 1,
                      fontWeight: 600,
                      fontSize: '1.125rem',
                    }}
                  >
                    {step.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: 'text.secondary',
                      lineHeight: 1.5,
                    }}
                  >
                    {step.description}
                  </Typography>
                </Box>
              </StepCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HowItWorksVC; 
import React, { useState, useEffect, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemText, Drawer } from '@mui/material';
import { styled, alpha } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ColorModeContext } from '../../theme';
import { Button } from '../ui/Button';
import { trackTwitterConversion } from '../../utils/analytics';

// Import the logo
import LogoBlack from "./assets/tp_new_logo_black.svg";
import LogoWhite from "./assets/tp_horizontal_whitetext_logo.svg";
import { ReactComponent as LogoNoText } from "./assets/LogoNoText.svg";

const AnimatedLogo = styled('img')(({ theme }) => ({
  height: '40px',
  marginTop: '10px',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    filter: 'brightness(1.2)',
    cursor: 'pointer',
  },
}));

const MenuLink = styled('a')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1rem',
  fontWeight: 500,
  textDecoration: 'none',
  cursor: 'pointer',
  transition: 'color 0.2s ease',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

const mobileMenuItems = [
  { label: 'Features', href: '#features' },
  { label: 'How It Works', href: '#how-it-works' },
  { label: 'Pricing', href: '#pricing' },
  { label: 'Contact', href: '#contact' },
];

const AppAppBarVC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const getBackgroundColor = () => {
    if (!isScrolled) return 'transparent';
    return theme.palette.mode === 'light'
      ? alpha(theme.palette.background.paper, 0.9)
      : alpha(theme.palette.background.default, 0.9);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setDrawerOpen(false);
  };

  const handleDemoRequest = () => {
    trackTwitterConversion({
      value: 1,
      contents: 'demo_request',
      status: 'initiated'
    });
    // Open Calendly in new tab
    window.open('https://calendly.com/tim-truepipe', '_blank');
  };

  const renderMobileMenu = () => (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={handleDrawerToggle}
      PaperProps={{
        sx: { width: '100%', backgroundColor: theme.palette.background.default }
      }}
    >
      <Box sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <LogoNoText width="40" height="40" />
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {mobileMenuItems.map((item) => (
            <ListItem 
              key={item.label} 
              onClick={() => scrollToSection(item.href.substring(1))}
              sx={{ 
                py: 2,
                borderBottom: `1px solid ${theme.palette.divider}`
              }}
            >
              <ListItemText 
                primary={item.label} 
                primaryTypographyProps={{ 
                  variant: 'h6',
                  sx: { fontWeight: 'bold' }
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        transition: 'all 0.15s cubic-bezier(1, 0, 0, 1)',
        backdropFilter: isScrolled ? 'blur(5px)' : 'none',
        backgroundColor: getBackgroundColor(),
        boxShadow: isScrolled ? theme.shadows[1] : 'none',
        borderRadius: isScrolled ? '0.5rem' : '0',
        zIndex: 5,
        top: isScrolled ? '0.5rem' : '0',
        left: isScrolled ? '2.5%' : '0',
        right: isScrolled ? '2.5%' : '0',
        width: isScrolled ? '95%' : '100%',
      }}
    >
      <Container maxWidth="lg">
        <Toolbar sx={{ justifyContent: 'space-between', height: 64 }}>
          <Link to="/" style={{ textDecoration: 'none' }}>
            {isMobile ? (
              <LogoNoText width="40" height="40" />
            ) : (
              <AnimatedLogo
                src={theme.palette.mode === 'dark' ? LogoWhite : LogoBlack}
                alt="truepipe"
              />
            )}
          </Link>

          {isMobile ? (
            <IconButton
              color="inherit"
              onClick={handleDrawerToggle}
              sx={{ color: theme.palette.text.primary }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <MenuLink href="#features">Features</MenuLink>
              <MenuLink href="#how-it-works">How It Works</MenuLink>
              <MenuLink href="#pricing">Pricing</MenuLink>
              <MenuLink href="#contact">Contact</MenuLink>
              <Button
                onClick={handleDemoRequest}
                sx={{ 
                  fontSize: '1rem !important',
                  py: 1.5,
                  px: 3,
                  background: 'linear-gradient(to right, #059669, #0891b2)',
                  '&:hover': {
                    background: 'linear-gradient(to right, #047857, #0369a1)',
                  }
                }}
              >
                Request Demo
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>

      {renderMobileMenu()}
    </AppBar>
  );
};

export default AppAppBarVC; 
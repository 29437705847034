import React, { useState, useMemo } from 'react';
import { format } from 'date-fns';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Calendar, TrendingUp, Users, DollarSign, ArrowUpRight, AlertTriangle } from 'lucide-react';
import { generatePortfolioCompanies, filterCompaniesByTimeframe, calculateMetrics, sectors, companyNamesBySector } from '../utils/portfolioData';

const SECTOR_COLORS = {
  'AI/ML': '#2563eb',        // Blue
  'Data Analytics': '#3b82f6', // Light Blue
  'Cybersecurity': '#6366f1', // Indigo
  'Healthcare': '#10b981',    // Green
  'FinTech': '#8b5cf6',      // Purple
  'EdTech': '#f59e0b',       // Amber
  'CleanTech': '#22c55e'     // Emerald
};

const statusColors = {
  Outperforming: 'bg-green-100 text-green-800',
  Performing: 'bg-blue-100 text-blue-800',
  Watch: 'bg-yellow-100 text-yellow-800',
  Underperforming: 'bg-red-100 text-red-800'
};

const generateForecast = (companies) => {
  if (!companies.length) {
    return {
      predictedARR: 0,
      confidenceLevel: 0,
      riskScore: 'High',
      growthDrivers: [],
      riskFactors: {
        marketCompetition: 'High',
        churnRisk: 'High',
        cashRunway: 'High',
        techAdoption: 'High'
      }
    };
  }

  const totalMRR = companies.reduce((sum, company) => sum + company.mrr, 0);
  const avgGrowth = companies.reduce((sum, company) => sum + company.yoyGrowth, 0) / companies.length;
  
  // Calculate growth trend from historical data
  const historicalGrowth = companies.reduce((sum, company) => {
    const history = company.historicalMRR;
    if (history.length >= 2) {
      const oldest = history[0].value;
      const newest = history[history.length - 1].value;
      return sum + ((newest - oldest) / oldest * 100);
    }
    return sum + company.yoyGrowth;
  }, 0) / companies.length;

  // Use historical growth to adjust confidence
  const growthVolatility = Math.abs(historicalGrowth - avgGrowth) / avgGrowth;
  const baseConfidence = 85 - (companies.length > 5 ? 0 : 15);
  const adjustedConfidence = baseConfidence * (1 - growthVolatility);
  
  return {
    predictedARR: totalMRR * 12 * (1 + (historicalGrowth / 100)),
    confidenceLevel: Math.round(Math.max(adjustedConfidence, 40)),
    riskScore: companies.some(c => c.runway < 12) ? 'Medium' : 'Low',
    growthDrivers: [
      { name: 'Product Updates', impact: Math.round(10 + Math.random() * 10) },
      { name: 'Market Expansion', impact: Math.round(8 + Math.random() * 8) },
      { name: 'Team Growth', impact: Math.round(5 + Math.random() * 8) },
      { name: 'Customer Acquisition', impact: Math.round(5 + Math.random() * 5) }
    ].sort((a, b) => b.impact - a.impact), // Sort by impact
    riskFactors: {
      marketCompetition: companies.length > 3 ? 'Medium' : 'High',
      churnRisk: companies.some(c => c.churnRate > 3) ? 'Medium' : 'Low',
      cashRunway: companies.some(c => c.runway < 12) ? 'Medium' : 'Low',
      techAdoption: Math.random() > 0.5 ? 'Medium' : 'Low'
    }
  };
};

const HomepageDemo = () => {
  const [selectedCompany, setSelectedCompany] = useState("all");
  const [timeframe, setTimeframe] = useState("6m");
  const [portfolioCompanies] = useState(() => generatePortfolioCompanies(10));

  const filteredCompanies = useMemo(() => {
    const filtered = filterCompaniesByTimeframe(portfolioCompanies, timeframe);
    if (selectedCompany !== "all") {
      return filtered.filter(company => company.id.toString() === selectedCompany);
    }
    return filtered;
  }, [portfolioCompanies, timeframe, selectedCompany]);

  const metrics = useMemo(() => 
    calculateMetrics(filteredCompanies), 
    [filteredCompanies]
  );

  const { totalValue, averageMRR, totalHeadcount, averageRunway, companiesAtRisk } = metrics;

  // Calculate sector distribution dynamically
  const sectorDistribution = useMemo(() => {
    const sectorCounts = filteredCompanies.reduce((acc, company) => {
      acc[company.sector] = (acc[company.sector] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(sectorCounts).map(([name, value]) => ({
      name,
      value
    }));
  }, [filteredCompanies]);

  const chartData = useMemo(() => {
    const allMRR = filteredCompanies.reduce((acc, company) => {
      company.historicalMRR.forEach(({ date, value }) => {
        const month = format(new Date(date), 'MMM');
        acc[date] = (acc[date] || 0) + value;
      });
      return acc;
    }, {});

    return Object.entries(allMRR)
      .map(([date, value]) => ({
        month: format(new Date(date), 'MMM'),
        arr: value
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [filteredCompanies]);

  const forecast = useMemo(() => 
    generateForecast(filteredCompanies),
    [filteredCompanies]
  );

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-4 border rounded shadow">
          <p className="text-sm">{`${payload[0].name}: ${payload[0].value}%`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white p-4 sm:p-8">
      <div className="max-w-7xl mx-auto">
        {/* Header with filters */}
        <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-8 mb-8">
          <div className="flex-1">
            <h1 className="text-3xl font-bold text-gray-900">Portfolio Dashboard</h1>
            <p className="text-gray-500">Last updated: December 30, 2024</p>
          </div>
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 ml-4">
            <Select value={selectedCompany} onValueChange={setSelectedCompany}>
              <SelectTrigger className="w-full sm:w-48">
                <SelectValue placeholder="Select Company" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Companies</SelectItem>
                {portfolioCompanies.map(company => (
                  <SelectItem key={company.id} value={company.id.toString()}>
                    {company.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Select value={timeframe} onValueChange={setTimeframe}>
              <SelectTrigger className="w-full sm:w-32">
                <SelectValue placeholder="Timeframe" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="1m">1 Month</SelectItem>
                <SelectItem value="3m">3 Months</SelectItem>
                <SelectItem value="6m">6 Months</SelectItem>
                <SelectItem value="1y">1 Year</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>

        {/* Key Metrics Row */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 mb-8">
          {/* Portfolio Value Card */}
          <Card className="bg-gradient-to-br from-blue-50 to-white">
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                Portfolio Value
              </CardTitle>
              <DollarSign className="h-4 w-4 text-blue-500" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold text-blue-600">
                ${(totalValue / 1000000).toFixed(1)}M
              </div>
              <p className="text-xs text-gray-500">
                Across {filteredCompanies.length} companies
              </p>
            </CardContent>
          </Card>

          {/* Average MRR Card */}
          <Card className="bg-gradient-to-br from-green-50 to-white">
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                Average MRR
              </CardTitle>
              <TrendingUp className="h-4 w-4 text-green-500" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold text-green-600">
                ${(averageMRR / 1000).toFixed(1)}K
              </div>
              <p className="text-xs text-green-500 flex items-center gap-1">
                <ArrowUpRight className="h-3 w-3" />
                +12.3% from last month
              </p>
            </CardContent>
          </Card>

          {/* Headcount Card */}
          <Card className="bg-gradient-to-br from-purple-50 to-white">
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                Total Headcount
              </CardTitle>
              <Users className="h-4 w-4 text-purple-500" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold text-purple-600">
                {totalHeadcount}
              </div>
              <p className="text-xs text-purple-500 flex items-center gap-1">
                <ArrowUpRight className="h-3 w-3" />
                +45 this quarter
              </p>
            </CardContent>
          </Card>

          {/* Runway Card */}
          <Card className="bg-gradient-to-br from-orange-50 to-white">
            <CardHeader className="flex flex-row items-center justify-between pb-2">
              <CardTitle className="text-sm font-medium text-gray-500">
                Avg Runway
              </CardTitle>
              <Calendar className="h-4 w-4 text-orange-500" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold text-orange-600">
                {averageRunway.toFixed(1)} months
              </div>
              <p className="text-xs text-orange-500 flex items-center gap-1">
                <AlertTriangle className="h-3 w-3" />
                {companiesAtRisk} companies &lt; 12 months
              </p>
            </CardContent>
          </Card>
        </div>

        {/* Charts Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
          {/* Revenue Growth Chart */}
          <Card>
            <CardHeader>
              <CardTitle>Portfolio ARR Growth</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                    <XAxis dataKey="month" />
                    <YAxis tickFormatter={(value) => `${(value / 1000000).toFixed(1)}M`} />
                    <Tooltip formatter={(value) => `${(value / 1000000).toFixed(1)}M`} />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="arr"
                      stroke="#2563eb"
                      strokeWidth={2}
                      name="Total MRR"
                      dot={{ stroke: '#2563eb', strokeWidth: 2, r: 4 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>

          {/* Sector Distribution Chart */}
          <Card>
            <CardHeader>
              <CardTitle>Sector Distribution</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={sectorDistribution}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={100}
                      fill="#8884d8"
                      paddingAngle={5}
                      dataKey="value"
                    >
                      {sectorDistribution.map((entry) => (
                        <Cell 
                          key={`cell-${entry.name}`} 
                          fill={SECTOR_COLORS[entry.name]} 
                        />
                      ))}
                    </Pie>
                    <Tooltip 
                      content={({ active, payload }) => {
                        if (active && payload && payload.length) {
                          const data = payload[0].payload;
                          return (
                            <div className="bg-white p-3 border rounded-lg shadow">
                              <p className="font-medium" style={{ color: SECTOR_COLORS[data.name] }}>
                                {data.name}
                              </p>
                              <p className="text-sm text-gray-600">
                                {data.value} {data.value === 1 ? 'company' : 'companies'}
                              </p>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Legend
                      formatter={(value, entry) => (
                        <span style={{ color: SECTOR_COLORS[value] || '#94a3b8' }}>
                          {value}
                        </span>
                      )}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* AI Forecast Section */}
        <Card className="mb-8">
          <CardHeader className="flex flex-row items-center justify-between">
            <div>
              <CardTitle>AI-Powered Growth Forecast</CardTitle>
              <p className="text-sm text-gray-500 mt-1">
                Based on {filteredCompanies.length} {filteredCompanies.length === 1 ? 'company' : 'companies'}
              </p>
            </div>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
              <div className="p-4 bg-blue-50 rounded-lg">
                <div className="text-sm text-gray-500 mb-1">Predicted ARR (12m)</div>
                <div className="text-2xl font-bold text-blue-600">
                  ${(forecast.predictedARR / 1000000).toFixed(1)}M
                </div>
                <div className="text-xs text-blue-600 mt-1">
                  +{((forecast.predictedARR / (filteredCompanies.reduce((sum, c) => sum + c.mrr, 0) * 12) - 1) * 100).toFixed(1)}% YoY Growth
                </div>
              </div>
              <div className="p-4 bg-green-50 rounded-lg">
                <div className="text-sm text-gray-500 mb-1">Confidence Level</div>
                <div className="text-2xl font-bold text-green-600">{forecast.confidenceLevel}%</div>
                <div className="text-xs text-green-600 mt-1">
                  Based on {filteredCompanies.length} {filteredCompanies.length === 1 ? 'company' : 'companies'}
                </div>
              </div>
              <div className="p-4 bg-purple-50 rounded-lg">
                <div className="text-sm text-gray-500 mb-1">Risk Score</div>
                <div className="text-2xl font-bold text-purple-600">{forecast.riskScore}</div>
                <div className="text-xs text-purple-600 mt-1">
                  {forecast.riskScore === 'Low' ? 'Market conditions favorable' : 'Requires attention'}
                </div>
              </div>
            </div>

            <div className="space-y-6">
              <div>
                <h4 className="text-sm font-medium mb-2">Key Growth Drivers</h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {forecast.growthDrivers.map((driver, index) => (
                    <div key={driver.name} className="flex items-center gap-2 text-sm">
                      <div className={`w-2 h-2 rounded-full ${
                        index === 0 ? 'bg-green-500' :
                        index === 1 ? 'bg-blue-500' :
                        index === 2 ? 'bg-purple-500' : 'bg-orange-500'
                      }`}></div>
                      <span>{driver.name} (+{driver.impact}%)</span>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <h4 className="text-sm font-medium mb-2">Risk Factors</h4>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  {Object.entries(forecast.riskFactors).map(([key, value]) => (
                    <div key={key} className="flex items-center justify-between bg-gray-50 p-3 rounded">
                      <span className="text-sm">{key.split(/(?=[A-Z])/).join(' ')}</span>
                      <span className={`text-xs font-medium ${
                        value === 'Low' ? 'text-green-600' :
                        value === 'Medium' ? 'text-yellow-600' : 'text-red-600'
                      }`}>
                        {value}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Portfolio Companies Table */}
        <Card>
          <CardHeader>
            <CardTitle>Portfolio Overview</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="overflow-x-auto -mx-4 sm:mx-0">
              <div className="min-w-[800px] sm:min-w-0">
                <table className="w-full text-sm">
                  <thead>
                    <tr className="border-b">
                      <th className="text-left p-4">Company</th>
                      <th className="text-left p-4">Stage</th>
                      <th className="text-right p-4">MRR</th>
                      <th className="text-right p-4">Growth</th>
                      <th className="text-right p-4">Runway</th>
                      <th className="text-center p-4">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredCompanies.map(company => (
                      <tr key={company.id} className="border-b hover:bg-gray-50">
                        <td className="p-4">
                          <div className="font-medium">{company.name}</div>
                          <div className="inline-flex items-center px-2 py-0.5 rounded text-xs" 
                               style={{ 
                                 backgroundColor: `${SECTOR_COLORS[company.sector]}20`,
                                 color: SECTOR_COLORS[company.sector] 
                               }}>
                            {company.sector}
                          </div>
                        </td>
                        <td className="p-4">{company.stage}</td>
                        <td className="text-right p-4">${(company.mrr / 1000).toFixed(1)}K</td>
                        <td className="text-right p-4 text-green-600">+{company.yoyGrowth}%</td>
                        <td className="text-right p-4">{company.runway}mo</td>
                        <td className="text-center p-4">
                          <span className={`px-2 py-1 rounded-full text-xs ${statusColors[company.status]}`}>
                            {company.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default HomepageDemo; 
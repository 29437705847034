import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from '../ui/Button';
import vcHeroImage from './assets/vcHeroImage.png';
import { trackTwitterConversion } from '../../utils/analytics';

// Styled components
const GradientBanner = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: theme.spacing(1, 3),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(16, 185, 129, 0.1)' 
    : '#ECFDF5',
  borderRadius: '9999px',
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(to right, #34d399, #06b6d4)'
    : 'linear-gradient(to right, #059669, #0891b2)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  display: 'inline',
}));

const HeroImage = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '940px',
  height: 'auto',
  marginTop: theme.spacing(6),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[4],
}));

const HeroVC = ({ onOpenSignupModal }) => {
  const handleSignupClick = () => {
    // Track the conversion
    trackTwitterConversion({
      value: 1,
      contents: 'portfolio_analysis',
      status: 'initiated'
    });
    
    // Call the original handler
    onOpenSignupModal();
  };

  return (
    <Box 
      component="section"
      sx={{ 
        position: 'relative',
        bgcolor: 'background.default',
        pt: { xs: 4, sm: 6 },
        pb: { xs: 2, sm: 3 },
      }}
    >
      <Container maxWidth="lg">
        <Grid 
          container 
          spacing={4}
          alignItems="flex-start"
        >
          {/* Left Column - Content */}
          <Grid item xs={12} lg={6}>
            <Box sx={{ textAlign: { xs: 'left' } }}>
              <GradientBanner>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    color: '#059669',
                    fontWeight: 500,
                    fontSize: '0.875rem'
                  }}
                >
                  Limited Time: Free Portfolio Analysis
                </Typography>
              </GradientBanner>

              <Typography 
                variant="h1" 
                sx={{ 
                  fontSize: { xs: '2.5rem', sm: '3.5rem' },
                  fontWeight: 700,
                  lineHeight: 1.1,
                  color: 'text.primary',
                  mb: 2,
                }}
              >
                Real-time revenue{' '}
                <br />
                and retention insights{' '}
                <GradientText component="span" variant="inherit">
                  for your entire portfolio
                </GradientText>
              </Typography>

              <Typography
                variant="h2"
                sx={{ 
                  fontSize: { xs: '1.125rem', sm: '1.25rem' },
                  color: 'text.secondary',
                  mb: 4,
                  lineHeight: 1.6,
                }}
              >
                Join 100+ VC firms getting real-time portfolio insights. No more waiting for quarterly board meetings - get instant access to revenue data.
              </Typography>

              <Box 
                sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3,
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: { xs: 'center', lg: 'flex-start' },
                }}
              >
                <Button 
                  size="large"
                  onClick={handleSignupClick}
                  sx={{ 
                    fontSize: '0.875rem !important',
                    minWidth: '280px',
                    padding: '12px 32px',
                  }}
                >
                  Get Your Free Portfolio Analysis
                </Button>
                <Typography 
                  variant="body2"
                  sx={{ 
                    color: 'text.secondary',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  ⭐️ 4.9/5 from 100+ VCs
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Right Column - Image */}
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <HeroImage 
                src={vcHeroImage} 
                alt="Portfolio Analytics Dashboard"
                sx={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '100%',
                  borderRadius: 2,
                  boxShadow: 4,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HeroVC; 
// SixMonthForecastChart.jsx v2.2.0
// Renders a unified line chart of historical and forecast data using Recharts

import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { use6MonthForecast } from './6MonthForecastProvider';
import { Box, Typography, CircularProgress, useTheme } from '@mui/material';
import { useChartStyles } from '../components/hooks/useChartStyles';

// Custom tooltip component specific to 6MonthForecast
const ForecastTooltip = ({ active, payload, label }) => {
  const theme = useTheme();
  const { getTooltipStyles, getChartColor } = useChartStyles();
  const tooltipStyles = getTooltipStyles();

  if (!active || !payload || !payload.length) return null;

  // Parse the YYYY-MM date string
  const [year, month] = label.split('-');
  const dateForLabel = new Date(`${year}-${month}-01`);

  // Get the historical value for comparison
  const historicalValue = payload.find(p => p.name === 'Historical')?.value || 0;
  const expectedValue = payload.find(p => p.name === 'Expected')?.value || 0;
  const difference = expectedValue - historicalValue;
  const percentageChange = historicalValue ? (difference / historicalValue) * 100 : 0;

  return (
    <Box sx={{
      ...tooltipStyles.container,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[3],
      padding: 0,
      justifyContent: 'space-between',
      height: 'auto',
      minWidth: '170px',
      width: 'auto',
      overflow: 'hidden',
      '& .MuiTypography-root': {
        fontSize: '0.875rem',
      },
    }}>
      <Box sx={{
        ...tooltipStyles.header,
        backgroundColor: theme.palette.mode === 'light' 
          ? theme.palette.grey[200] 
          : theme.palette.grey[800],
        color: theme.palette.mode === 'light'
          ? theme.palette.text.primary
          : theme.palette.common.white,
        padding: theme.spacing(1, 2),
        width: '100%',
      }}>
        <Typography variant="subtitle2">
          {dateForLabel.toLocaleDateString()}
        </Typography>
      </Box>
      <Box sx={{
        ...tooltipStyles.content,
        padding: theme.spacing(1, 1.5),
      }}>
        {payload.map((entry, index) => (
          <Box key={entry.name} sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: theme.spacing(0.5),
          }}>
            <Typography sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: theme.spacing(1),
            }}>
              <Box sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: getChartColor(
                  entry.name === 'Historical' ? 0 :
                  entry.name === 'Best Case' ? 1 :
                  entry.name === 'Expected' ? 2 : 3
                ),
                marginRight: theme.spacing(1),
              }} />
              {entry.name}:
            </Typography>
            <Typography>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0
              }).format(entry.value || 0)}
            </Typography>
          </Box>
        ))}
        {historicalValue > 0 && expectedValue > 0 && (
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: theme.spacing(1),
            borderTop: `1px solid ${theme.palette.divider}`,
            paddingTop: theme.spacing(1),
          }}>
            <Typography sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: theme.spacing(1),
            }}>
              YoY Change:
            </Typography>
            <Typography sx={{
              color: difference >= 0 ? theme.palette.success.main : theme.palette.error.main
            }}>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
                signDisplay: 'always'
              }).format(difference)}
              {` (${new Intl.NumberFormat('en-US', {
                style: 'percent',
                signDisplay: 'always',
                maximumFractionDigits: 1
              }).format(percentageChange / 100)})`}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const SixMonthForecastChart = () => {
  const {
    combinedData,
    loading,
    error,
    formatCurrency,
    formatDate,
  } = use6MonthForecast();

  const {
    getCommonChartProperties,
    getCustomTooltip,
    getChartColor,
    isMobile,
  } = useChartStyles();

  // Get common chart properties for line chart
  const chartProps = getCommonChartProperties('line');

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={400}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    console.error('[SixMonthForecastChart] Error rendering chart:', error);
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={400}>
        <Typography color="error">Error loading forecast data</Typography>
      </Box>
    );
  }

  if (!combinedData || combinedData.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height={400}>
        <Typography>No forecast data available.</Typography>
      </Box>
    );
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={combinedData}
        margin={chartProps.margin}
      >
        <XAxis
          dataKey="month"
          tickFormatter={(value) => {
            const [year, m] = value.split('-');
            return `${m.padStart(2, '0')}/${year.slice(-2)}`;
          }}
          {...chartProps.axisBottom}
        />

        <YAxis
          tickFormatter={(val) => formatCurrency(val)}
          {...chartProps.axisLeft}
        />

        <Tooltip content={<ForecastTooltip />} />

        <Legend {...chartProps.legends} />

        {/* Historical line */}
        <Line
          type="monotone"
          dataKey="value"
          name="Historical"
          stroke={getChartColor(0)}
          strokeWidth={2}
          dot={!isMobile}
          connectNulls
        />

        {/* Forecast lines */}
        <Line
          type="monotone"
          dataKey="bestCase"
          name="Best Case"
          stroke={getChartColor(1)}
          strokeWidth={2}
          dot={!isMobile}
          strokeDasharray="5 5"
          connectNulls
        />
        <Line
          type="monotone"
          dataKey="expected"
          name="Expected"
          stroke={getChartColor(2)}
          strokeWidth={2}
          dot={!isMobile}
          strokeDasharray="3 3"
          connectNulls
        />
        <Line
          type="monotone"
          dataKey="worstCase"
          name="Worst Case"
          stroke={getChartColor(3)}
          strokeWidth={2}
          dot={!isMobile}
          strokeDasharray="2 2"
          connectNulls
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SixMonthForecastChart;

// 6MonthForecastProvider.jsx v2.2.0
import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { collection, query, onSnapshot } from 'firebase/firestore';
import { useFirebase } from './FirebaseContext';
import { useAuth } from './AuthContext';

const formatters = {
  formatCurrency: (value) => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value),
  formatPercentage: (value) => `${(value * 100).toFixed(1)}%`,
  formatDate: (date) => new Date(date).toLocaleDateString(),
  formatNumber: (value) => new Intl.NumberFormat('en-US').format(value),
};

const calculateTruepipeProbability = (deal) => {
  const probability = parseFloat(deal.hs_deal_stage_probability || 0);
  
  // Apply age factor to reduce probability of older deals
  const createdAt = deal.createdate ? new Date(deal.createdate) : null;
  let ageFactor = 1;
  if (createdAt && !isNaN(createdAt)) {
    const ageInDays = (Date.now() - createdAt.getTime()) / (1000 * 60 * 60 * 24);
    ageFactor = Math.max(0.8, 1 - (ageInDays / 365));
  }

  return probability * ageFactor;
};

const SixMonthForecastContext = createContext();

export const use6MonthForecast = () => useContext(SixMonthForecastContext);

export const SixMonthForecastProvider = ({ children }) => {
  const { db } = useFirebase();
  const { currentUser } = useAuth();
  const [historicalData, setHistoricalData] = useState([]);
  const [forecastData, setForecastData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const logDebug = (msg, data = {}) => console.debug(`[6MonthForecastProvider] ${msg}`, data);
  const logError = (err) => console.error('[6MonthForecastProvider] Error:', err);

  const toNumber = (val) => (typeof val === 'number' ? val : parseFloat(val) || 0);

  const processDeals = (deals) => {
    const now = new Date();
    const historicalDeals = [];
    const forecastDeals = [];

    deals.forEach((deal) => {
      try {
        const closeDate = new Date(deal.closedate);
        if (isNaN(closeDate)) throw new Error('Invalid close date format');
        
        const probability = parseFloat(deal.hs_deal_stage_probability || 0);
        
        if (closeDate <= now) {
          // Only include won deals (probability = 1.0) in historical data
          if (probability === 1.0) {
            historicalDeals.push({ ...deal, closeDate });
          }
        } else {
          // Only include open deals (0 < probability < 1) in forecast
          if (probability > 0.0 && probability < 1.0) {
            forecastDeals.push({ ...deal, closeDate });
          }
        }
      } catch (err) {
        logError(`Failed to parse deal ${deal.id}: ${err.message}`);
      }
    });

    const historical = aggregateHistorical(historicalDeals);
    const forecast = aggregateForecast(forecastDeals);
    return { historical, forecast };
  };

  const aggregateHistorical = (deals) => {
    const twelveMonthsAgo = new Date();
    twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);

    const map = {};
    deals.forEach((d) => {
      if (d.closeDate >= twelveMonthsAgo) {
        const monthKey = `${d.closeDate.getFullYear()}-${String(d.closeDate.getMonth() + 1).padStart(2, '0')}`;
        if (!map[monthKey]) map[monthKey] = { value: 0 };
        map[monthKey].value += toNumber(d.amount);
      }
    });

    return Object.entries(map)
      .map(([month, data]) => ({ month, value: data.value, type: 'historical' }))
      .sort((a, b) => a.month.localeCompare(b.month));
  };

  const aggregateForecast = (deals) => {
    const sixMonthsAhead = new Date();
    sixMonthsAhead.setMonth(sixMonthsAhead.getMonth() + 6);

    const map = {};
    deals.forEach((d) => {
      if (d.closeDate <= sixMonthsAhead) {
        const monthKey = `${d.closeDate.getFullYear()}-${String(d.closeDate.getMonth() + 1).padStart(2, '0')}`;
        if (!map[monthKey]) map[monthKey] = { bestCase: 0, worstCase: 0, expected: 0 };
        
        const amount = toNumber(d.amount);
        const probability = calculateTruepipeProbability(d);

        map[monthKey].bestCase += probability > 0.7 ? amount : amount * 0.5;
        map[monthKey].worstCase += probability > 0.9 ? amount * 0.8 : 0;
        map[monthKey].expected += amount * probability;
      }
    });

    return Object.entries(map)
      .map(([month, data]) => ({ month, ...data, type: 'forecast' }))
      .sort((a, b) => a.month.localeCompare(b.month));
  };

  const mergeData = (historical, forecast) => {
    const mergedMap = {};

    historical.forEach((h) => {
      mergedMap[h.month] = { month: h.month, value: h.value };
    });

    forecast.forEach((f) => {
      if (!mergedMap[f.month]) mergedMap[f.month] = { month: f.month };
      mergedMap[f.month].bestCase = f.bestCase;
      mergedMap[f.month].worstCase = f.worstCase;
      mergedMap[f.month].expected = f.expected;
    });

    return Object.values(mergedMap).sort((a, b) => a.month.localeCompare(b.month));
  };

  useEffect(() => {
    if (!currentUser) {
      logDebug('No user authenticated, clearing data.');
      setHistoricalData([]);
      setForecastData([]);
      setCombinedData([]);
      setLoading(false);
      return;
    }

    setLoading(true);
    const dealsRef = collection(db, 'users', currentUser.uid, 'deals');
    const dealsQuery = query(dealsRef);

    const unsubscribe = onSnapshot(
      dealsQuery,
      (snapshot) => {
        try {
          const deals = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          logDebug('Raw deals fetched', { dealsCount: deals.length });

          const { historical, forecast } = processDeals(deals);
          setHistoricalData(historical);
          setForecastData(forecast);
          setCombinedData(mergeData(historical, forecast));
          setLoading(false);
        } catch (err) {
          logError(err);
          setError(err);
          setLoading(false);
        }
      },
      (err) => {
        logError(err);
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [db, currentUser]);

  const value = useMemo(() => ({
    historicalData,
    forecastData,
    combinedData,
    loading,
    error,
    formatCurrency: formatters.formatCurrency,
    formatPercentage: formatters.formatPercentage,
    formatDate: formatters.formatDate,
    formatNumber: formatters.formatNumber,
  }), [historicalData, forecastData, combinedData, loading, error]);

  return <SixMonthForecastContext.Provider value={value}>{children}</SixMonthForecastContext.Provider>;
};

import React from 'react';
import { Box, Container, Typography, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button } from '../ui/Button';
import { trackTwitterConversion } from '../../utils/analytics';

const PricingCard = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(6),
  borderRadius: theme.shape.borderRadius * 4,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(28, 28, 28, 0.95)'
    : theme.palette.background.paper,
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    marginTop: theme.spacing(8),
  },
}));

const FeaturesList = styled(List)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  '& .MuiListItem-root': {
    padding: 0,
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.25rem',
    color: '#059669',
  },
}));

const PricingBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 4,
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(0, 0, 0, 0.2)'
    : 'rgb(249, 250, 251)',
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(5),
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const features = [
  'Real-time MRR & ARR tracking',
  'AI-powered revenue forecasting',
  'Sales performance analytics',
  'Portfolio-wide retention metrics',
  'Unlimited user seats',
  'Priority support',
  'Custom reporting',
  'API access'
];

const PricingVC = ({ onOpenSignupModal }) => {
  const handleDemoRequest = () => {
    trackTwitterConversion({
      value: 1,
      contents: 'pricing_demo_request',
      status: 'initiated'
    });
    window.open('https://calendly.com/tim-truepipe', '_blank');
  };

  return (
    <Box 
      id="pricing" 
      sx={{ 
        py: { xs: 8, md: 10 },
        bgcolor: 'background.default',
      }}
    >
      <Container maxWidth="lg">
        {/* Title Section */}
        <Box sx={{ textAlign: 'center', maxWidth: '800px', mx: 'auto', mb: 6 }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 700,
              lineHeight: 1.2,
              mb: 2,
            }}
          >
            Simple, transparent pricing
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: '1rem', sm: '1.125rem' },
              color: 'text.secondary',
              lineHeight: 1.5,
            }}
          >
            One plan, unlimited insights. Connect all your portfolio companies and unlock real-time analytics.
          </Typography>
        </Box>

        {/* Pricing Card */}
        <PricingCard>
          {/* Features Section */}
          <Box sx={{ 
            p: { xs: 3, sm: 4 },
            flex: 'auto',
          }}>
            <Typography variant="h4" sx={{ fontWeight: 700, fontSize: '1.5rem' }}>
              Annual subscription
            </Typography>
            <Typography 
              sx={{ 
                mt: 2,
                color: 'text.secondary',
                fontSize: '1rem',
                lineHeight: 1.5,
              }}
            >
              Get complete visibility into your portfolio companies' performance with our comprehensive analytics platform.
            </Typography>

            {/* Features List Header */}
            <Typography 
              variant="subtitle2"
              sx={{ 
                color: '#059669',
                fontWeight: 600,
                mt: 4,
                mb: 3,
              }}
            >
              What's included
            </Typography>

            <FeaturesList>
              {features.map((feature) => (
                <ListItem key={feature}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <CheckCircleIcon />
                  </ListItemIcon>
                  <ListItemText 
                    primary={feature}
                    primaryTypographyProps={{
                      variant: 'body2',
                      color: 'text.secondary',
                    }}
                  />
                </ListItem>
              ))}
            </FeaturesList>
          </Box>

          {/* Pricing Section */}
          <Box sx={{ 
            p: 3,
            width: { lg: '35%' },
          }}>
            <PricingBox>
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  color: 'text.secondary',
                  fontWeight: 500,
                  fontSize: '0.9375rem',
                }}
              >
                Per portfolio company
              </Typography>
              <Box sx={{ 
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'center',
                gap: 1,
                my: 4,
              }}>
                <Typography 
                  variant="h2"
                  sx={{ 
                    fontWeight: 800,
                    fontSize: '3.5rem',
                    lineHeight: 1,
                  }}
                >
                  $1,000
                </Typography>
                <Typography 
                  variant="subtitle1" 
                  sx={{ 
                    color: 'text.secondary',
                    fontSize: '1.125rem',
                    fontWeight: 500,
                  }}
                >
                  /year
                </Typography>
              </Box>
              <Button
                size="large"
                fullWidth
                onClick={handleDemoRequest}
                sx={{ 
                  fontSize: '1rem !important',
                  py: 1.5,
                  mb: 3,
                  background: 'linear-gradient(to right, #059669, #0891b2)',
                  '&:hover': {
                    background: 'linear-gradient(to right, #047857, #0369a1)',
                  }
                }}
              >
                Request Demo
              </Button>
              <Typography 
                variant="caption" 
                sx={{ 
                  color: 'text.secondary',
                  fontSize: '0.8125rem',
                  opacity: 0.8,
                }}
              >
                Invoices and receipts available for easy company reimbursement
              </Typography>
            </PricingBox>
          </Box>
        </PricingCard>
      </Container>
    </Box>
  );
};

export default PricingVC; 
import { format, subMonths } from 'date-fns';

/**
 * @typedef {Object} PortfolioCompany
 * @property {number} id
 * @property {string} name
 * @property {string} sector
 * @property {string} stage
 * @property {number} valuation
 * @property {string} lastRound
 * @property {number} lastRoundSize
 * @property {number} ownership
 * @property {number} mrr
 * @property {number} yoyGrowth
 * @property {number} burnRate
 * @property {number} runway
 * @property {number} headcount
 * @property {number} churnRate
 * @property {string} lastUpdate
 * @property {'Outperforming' | 'Performing' | 'Watch' | 'Underperforming'} status
 * @property {Array<{date: string, value: number}>} historicalMRR
 */

/**
 * Generate historical MRR data for a company
 * @param {number} currentMRR
 * @param {number} months
 * @param {number} [volatility=0.1]
 */
const generateHistoricalMRR = (currentMRR, months, volatility = 0.1) => {
  const data = [];
  let mrr = currentMRR;

  for (let i = months; i >= 0; i--) {
    const date = format(subMonths(new Date(), i), 'yyyy-MM-dd');
    const randomChange = 1 + (Math.random() * 2 - 1) * volatility;
    mrr = mrr / (1 + (Math.random() * 0.1)); // Work backwards from current MRR
    data.push({
      date,
      value: Math.round(mrr)
    });
  }

  return data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
};

const sectors = ['AI/ML', 'Data Analytics', 'Cybersecurity', 'Healthcare', 'FinTech', 'EdTech', 'CleanTech'];

const companyNamesBySector = {
  'AI/ML': ['TechFlow AI', 'DataMind AI', 'AIops Platform'],
  'Data Analytics': ['DataSync Pro', 'CloudMatrix', 'QuantumLeap Tech'],
  'Cybersecurity': ['CloudSecure', 'CyberShield', 'CyberGuard Pro', 'SecureScale'],
  'Healthcare': ['HealthTech Plus', 'RoboHealth', 'BioTech Innovations'],
  'FinTech': ['FinServ AI', 'SmartFinance', 'NeuraTrade', 'BlockChain Connect'],
  'EdTech': ['EduMatrix'],
  'CleanTech': ['GreenEnergy Solutions', 'CleanTech Dynamics']
};

/**
 * Generate portfolio companies with realistic data
 * @param {number} [count=10]
 * @returns {Array<PortfolioCompany>}
 */
export const generatePortfolioCompanies = (count = 10) => {
  const stages = ['Seed', 'Series A', 'Series B', 'Series C'];
  const status = ['Outperforming', 'Performing', 'Watch', 'Underperforming'];

  // Flatten all company names while preserving sector information
  const allCompanies = sectors.flatMap(sector => 
    companyNamesBySector[sector].map(name => ({ name, sector }))
  );

  // Shuffle the companies array
  const shuffledCompanies = [...allCompanies].sort(() => Math.random() - 0.5);

  return Array.from({ length: Math.min(count, shuffledCompanies.length) }, (_, i) => {
    const mrr = Math.round(200000 + Math.random() * 1000000);
    const company = shuffledCompanies[i];
    
    return {
      id: i + 1,
      name: company.name,
      sector: company.sector,
      stage: stages[Math.floor(Math.random() * stages.length)],
      valuation: Math.round((mrr * 12) * (5 + Math.random() * 10)) * 1000,
      lastRound: stages[Math.floor(Math.random() * stages.length)].split(' ')[1],
      lastRoundSize: Math.round(5000000 + Math.random() * 45000000),
      ownership: Math.round(10 + Math.random() * 15),
      mrr,
      yoyGrowth: Math.round(50 + Math.random() * 150),
      burnRate: Math.round(mrr * (0.3 + Math.random() * 0.4)),
      runway: Math.round(12 + Math.random() * 18),
      headcount: Math.round(20 + Math.random() * 180),
      churnRate: Number((1 + Math.random() * 3).toFixed(1)),
      lastUpdate: format(subMonths(new Date(), Math.floor(Math.random() * 3)), 'yyyy-MM-dd'),
      status: status[Math.floor(Math.random() * status.length)],
      historicalMRR: generateHistoricalMRR(mrr, 12)
    };
  });
};

/**
 * Filter companies based on timeframe
 * @param {Array<PortfolioCompany>} companies
 * @param {string} timeframe
 * @returns {Array<PortfolioCompany>}
 */
export const filterCompaniesByTimeframe = (companies, timeframe) => {
  const monthsMap = { '1m': 1, '3m': 3, '6m': 6, '1y': 12 };
  const months = monthsMap[timeframe] || 12;
  const cutoffDate = subMonths(new Date(), months);

  return companies.map(company => ({
    ...company,
    historicalMRR: company.historicalMRR.filter(
      data => new Date(data.date) >= cutoffDate
    )
  }));
};

/**
 * Calculate portfolio metrics
 * @param {Array<PortfolioCompany>} companies
 * @returns {Object} Portfolio metrics
 */
export const calculateMetrics = (companies) => {
  return {
    totalValue: companies.reduce((sum, company) => 
      sum + (company.valuation * (company.ownership / 100)), 0),
    averageMRR: companies.reduce((sum, company) => 
      sum + company.mrr, 0) / companies.length,
    totalHeadcount: companies.reduce((sum, company) => 
      sum + company.headcount, 0),
    averageRunway: companies.reduce((sum, company) => 
      sum + company.runway, 0) / companies.length,
    companiesAtRisk: companies.filter(company => 
      company.runway < 12).length
  };
};

// Export sectors and companyNamesBySector for potential reuse
export { sectors, companyNamesBySector }; 
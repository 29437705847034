import React from 'react';
import { Box, Container, Typography, Grid, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { Button } from '../ui/Button';
import { useForm, ValidationError } from '@formspree/react';
import { trackTwitterConversion } from '../../utils/analytics';

const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  padding: '0.75rem 1rem',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(0, 0, 0, 0.2)'
    : theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
  fontSize: '0.875rem',
  '&::placeholder': {
    color: theme.palette.text.secondary,
  },
  '&:focus': {
    outline: 'none',
    borderColor: '#059669',
    boxShadow: `0 0 0 2px ${theme.palette.mode === 'dark' 
      ? 'rgba(5, 150, 105, 0.1)'
      : 'rgba(5, 150, 105, 0.1)'}`,
  },
}));

const StyledTextArea = styled('textarea')(({ theme }) => ({
  width: '100%',
  padding: '0.75rem 1rem',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(0, 0, 0, 0.2)'
    : theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
  fontSize: '0.875rem',
  resize: 'vertical',
  minHeight: '120px',
  '&::placeholder': {
    color: theme.palette.text.secondary,
  },
  '&:focus': {
    outline: 'none',
    borderColor: '#059669',
    boxShadow: `0 0 0 2px ${theme.palette.mode === 'dark' 
      ? 'rgba(5, 150, 105, 0.1)'
      : 'rgba(5, 150, 105, 0.1)'}`,
  },
}));

const ContactVC = () => {
  const [state, handleSubmit] = useForm("mgvwvqvw"); // Using the same Formspree endpoint as ContactUs
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  React.useEffect(() => {
    if (state.succeeded) {
      setOpenSnackbar(true);
      // Track successful form submission
      trackTwitterConversion({
        value: 1,
        contents: 'contact_form_submission',
        status: 'completed'
      });
      // Open Calendly after successful form submission
      window.open('https://calendly.com/tim-truepipe', '_blank');
    }
  }, [state.succeeded]);

  return (
    <Box 
      id="contact" 
      sx={{ 
        py: { xs: 8, md: 10 },
        bgcolor: 'background.default',
      }}
    >
      <Container maxWidth="lg">
        <Box 
          sx={{ 
            maxWidth: '800px',
            mx: 'auto',
            textAlign: 'center',
          }}
        >
          {/* Section Header */}
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 700,
              lineHeight: 1.2,
              mb: 2,
            }}
          >
            Ready to transform your portfolio management?
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: '1rem', sm: '1.125rem' },
              color: 'text.secondary',
              maxWidth: '600px',
              mx: 'auto',
              mb: 6,
              lineHeight: 1.5,
            }}
          >
            Get in touch to see how truepipe can provide real-time insights for your investment portfolio.
          </Typography>

          {/* Contact Form */}
          <Box 
            component="form" 
            onSubmit={handleSubmit}
            sx={{ 
              maxWidth: '600px',
              mx: 'auto',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <StyledInput
                  type="text"
                  name="name"
                  placeholder="Name"
                  required
                />
                <ValidationError 
                  prefix="Name" 
                  field="name"
                  errors={state.errors}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledInput
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                />
                <ValidationError 
                  prefix="Email" 
                  field="email"
                  errors={state.errors}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextArea
                  name="message"
                  placeholder="Message"
                  required
                />
                <ValidationError 
                  prefix="Message" 
                  field="message"
                  errors={state.errors}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  disabled={state.submitting}
                  sx={{ 
                    fontSize: '1rem !important',
                    py: 1.5,
                    background: 'linear-gradient(to right, #059669, #0891b2)',
                    '&:hover': {
                      background: 'linear-gradient(to right, #047857, #0369a1)',
                    }
                  }}
                >
                  Request Demo
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>

      {/* Success Notification */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
          onClose={() => setOpenSnackbar(false)}
        >
          Your message has been sent successfully! Redirecting you to schedule a demo...
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default ContactVC; 
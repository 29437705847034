// src/pages/ForecastDashboard.jsx v1.0.1
import React, { useEffect, useMemo } from 'react'
import { 
  Card,
  Button, 
  Alert, 
  Typography,
  Grid,
  Box,
  Container,
  Paper,
  LinearProgress
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, ReferenceLine } from 'recharts'
import { useDashboardTheme } from '../hooks/useDashboardTheme'
import {
  Warning as AlertCircle,
  CheckCircle as CheckCircle2,
  Cancel as XCircle,
  BarChart as BarChart4,
  Psychology as Brain,
  ShowChart,
  Report as FileWarning,
  TrendingUp,
  AttachMoney as CircleDollarSign,
  Speed,
  Timeline,
  Assessment,
  FileDownload,
  Refresh,
  Person,
  Group,
  AccessTime
} from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useForecast } from '../contexts/ForecastContext'
import { exportToPDF } from '../utils/pdfExport'
import '../utils/pdf.css'
import SkeletonLoader from '../utils/SkeletonLoader'
import ErrorDisplay from '../utils/ErrorDisplay'
import logger from '../utils/frontendLogger'
import TimeSeriesChart from '../components/TimeSeriesChart'
import CustomTooltip from '../components/charts/CustomTooltip'

function ForecastDashboard() {
  const { colors, isDark } = useDashboardTheme()

  const themeColors = {
    background: colors.background,
    cardBg: colors.card.background,
    cardHover: colors.card.hover,
    text: {
      primary: colors.text,
      secondary: colors.text.secondary || colors.text
    },
    status: {
      success: '#33D3B8',
      warning: '#ff9800',
      error: '#f44336',
      info: '#2196f3',
      purple: '#9c27b0'
    },
    chart: {
      actual: '#4A90E2',
      committed: '#9683EC',
      forecast: '#36B5AD',
      grid: colors.chart.grid,
      target: colors.chart.target
    },
    border: colors.card.border,
    progressBar: colors.progressBar,
    cta: {
      background: colors.cta.background,
      border: colors.cta.border,
      text: colors.cta.text,
      button: colors.cta.button,
      buttonHover: colors.cta.buttonHover
    }
  }

  // Default colors if themeColors is undefined
  const buttonPrimaryColor = themeColors?.button?.primary || '#1976d2'; // Default blue
  const buttonTextColor = themeColors?.button?.text || '#fff'; // Default white

  const MetricCard = ({ title, value, secondary, icon: Icon, trend, children, ...props }) => {
    return (
      <Card 
        sx={{ 
          p: 3,
          height: '100%',
          bgcolor: themeColors.cardBg,
          border: isDark ? `1px solid ${themeColors.border}` : 'none',
          '&:hover': {
            bgcolor: themeColors.cardHover
          }
        }}
        {...props}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            {title}
          </Typography>
          {Icon && <Icon sx={{ color: themeColors.icon }} />}
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'baseline', mb: 1 }}>
          <Typography variant="h4" component="div" fontWeight="bold">
            {value}
          </Typography>
          {secondary && (
            <Typography 
              variant="body2" 
              color={secondary.startsWith('+') ? themeColors.status.success : themeColors.status.error}
              sx={{ ml: 1 }}
            >
              {secondary}
            </Typography>
          )}
        </Box>
        
        {children}
      </Card>
    );
  };

  const RevenueMetric = ({ data }) => {
    const { formatCurrency } = useForecast();
    
    return (
      <MetricCard
        title="Revenue Performance"
        value={formatCurrency(data.current)}
        secondary={`${data.yoyGrowth >= 0 ? '+' : ''}${data.yoyGrowth.toFixed(1)}% YoY`}
        icon={CircleDollarSign}
        trend={data.trend}
      >
        <Box sx={{ height: 40 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data.trend}>
              <Line 
                type="monotone" 
                dataKey="value" 
                stroke={data.status === 'positive' ? themeColors.status.success : themeColors.status.error} 
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </MetricCard>
    );
  };

  const PipelineMetric = ({ data }) => {
    const { formatCurrency } = useForecast();
    
    return (
      <MetricCard
        title="Pipeline Coverage"
        value={`${data.coverage}x`}
        secondary={`Target: ${data.target}x`}
        icon={Assessment}
      >
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Pipeline Value: {formatCurrency(data.totalValue)}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <Typography variant="caption" color={themeColors.status.success}>
              {data.highConfidence} High Confidence
            </Typography>
            <Typography variant="caption" color={themeColors.status.error}>
              {data.atRisk} At Risk
            </Typography>
          </Box>
        </Box>
      </MetricCard>
    );
  };

  const ForecastMetric = ({ data }) => {
    const { formatCurrency } = useForecast();
    
    return (
      <MetricCard
        title="Q4 2024 Forecast"
        value={formatCurrency(data.forecast)}
        secondary={`${data.confidence}% Confidence`}
        icon={TrendingUp}
      >
        <Box sx={{ mt: 2 }}>
          <LinearProgress 
            variant="determinate" 
            value={data.progress} 
            sx={{ mb: 1, height: 8, borderRadius: 4 }}
          />
          <Typography variant="caption" color="text.secondary">
            {data.daysRemaining} days remaining in quarter
          </Typography>
        </Box>
      </MetricCard>
    );
  };

  const DealFlowMetric = ({ data }) => {
    const { formatCurrency } = useForecast();
    const { colors } = useDashboardTheme();
    
    return (
      <MetricCard
        title="Active Deals"
        value={data.totalActive}
        secondary={`Avg ${formatCurrency(data.avgDealSize)}`}
        icon={ShowChart}
      >
        <Box sx={{ mt: 2 }}>
          <Typography variant="caption" color={colors.status.success}>
            {data.distribution.closing30Days} Closing Next 30 Days
          </Typography>
          <Typography variant="caption" color={colors.status.error}>
            {data.distribution.closing90Days} Closing 31-90 Days
          </Typography>
          <Typography variant="caption" color={colors.text.secondary}>
            {data.distribution.beyondDeals} Beyond 90 Days
          </Typography>
        </Box>
      </MetricCard>
    );
  };

  const RiskFactorCard = ({ title, value, icon: Icon, description }) => {
    const isPositive = value >= 0;
    const normalizedValue = Math.min(Math.abs(value), 100); // Ensure value doesn't exceed 100%
    
    return (
      <Card sx={{ 
        p: 3,
        height: '100%',
        position: 'relative',
        overflow: 'visible'
      }}>
        {/* Header with Icon */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          mb: 2 
        }}>
          <Icon sx={{ 
            fontSize: 28,
            color: isPositive ? themeColors.status.success : themeColors.status.error,
            mr: 1.5
          }} />
          <Typography variant="h6" fontWeight="medium">
            {title}
          </Typography>
        </Box>

        {/* Metric Display */}
        <Box sx={{ mb: 2 }}>
          <Box sx={{ 
            display: 'flex',
            alignItems: 'baseline',
            gap: 1,
            mb: 1
          }}>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 'bold',
                color: isPositive ? themeColors.status.success : themeColors.status.error
              }}
            >
              {isPositive ? '+' : ''}{value}%
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ fontWeight: 'medium' }}
            >
              {value >= 75 ? 'Excellent' : 
               value >= 50 ? 'Good' :
               value >= 25 ? 'Fair' : 'Needs Attention'}
            </Typography>
          </Box>
          
          {/* Progress Bar */}
          <Box sx={{ position: 'relative', mt: 2 }}>
            <LinearProgress
              variant="determinate"
              value={normalizedValue}
              sx={{
                height: 8,
                borderRadius: 4,
                bgcolor: 'background.paper',
                '& .MuiLinearProgress-bar': {
                  bgcolor: isPositive ? themeColors.status.success : themeColors.status.error,
                  borderRadius: 4,
                }
              }}
            />
            {/* Target Marker */}
            <Box sx={{
              position: 'absolute',
              left: '50%',
              top: -2,
              bottom: -2,
              width: 2,
              bgcolor: 'divider',
              zIndex: 1
            }} />
          </Box>
        </Box>

        {/* Description */}
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{ 
            fontSize: '0.875rem',
            lineHeight: 1.5
          }}
        >
          {description}
        </Typography>
      </Card>
    );
  };

  const { 
    topMetrics,
    currentMRR,
    currentARR,
    forecastedDeals,
    monthlyForecast,
    predictedOutcomes,
    riskFactors,
    pipelineCoverage,
    growthMetrics,
    dealHealthMetrics,
    error,
    loading,
    formatCurrency,
    standardizedForecastData
  } = useForecast()

  // Get quarterlyTarget from topMetrics
  const quarterlyTarget = topMetrics?.quarterlyForecast?.forecast || 0;

  const projectedMRR = useMemo(() => {
    if (!monthlyForecast?.length) return 0;
    
    // Get current month (0-11)
    const currentMonth = new Date().getMonth();
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    // Get last 3 non-zero values to establish trend
    const nonZeroForecasts = monthlyForecast
      .filter(m => m.baseline > 0)
      .slice(-3);

    if (nonZeroForecasts.length === 0) return 0;

    // Calculate average growth rate from the last 3 non-zero months
    let growthRate = 0;
    if (nonZeroForecasts.length > 1) {
      const growthRates = [];
      for (let i = 1; i < nonZeroForecasts.length; i++) {
        const rate = (nonZeroForecasts[i].baseline - nonZeroForecasts[i-1].baseline) / nonZeroForecasts[i-1].baseline;
        growthRates.push(rate);
      }
      growthRate = growthRates.reduce((a, b) => a + b, 0) / growthRates.length;
    }

    // Get the latest actual value
    const lastNonZeroValue = nonZeroForecasts[nonZeroForecasts.length - 1].baseline;

    // Project forward using the growth rate
    const monthsToProject = 1; // Project one month forward
    const projectedValue = lastNonZeroValue * (1 + growthRate * monthsToProject);

    // Add optimistic/pessimistic bounds
    const projection = {
      baseline: Math.round(projectedValue),
      optimistic: Math.round(projectedValue * 1.15),
      pessimistic: Math.round(projectedValue * 0.85)
    };

    return projection.baseline || 
           ((projection.optimistic + projection.pessimistic) / 2) || 
           lastNonZeroValue || 0;
  }, [monthlyForecast]);
  
  useEffect(() => {
    logger.debug('ForecastDashboard state:', {
      hasForecastData: !!monthlyForecast?.length,
      hasOutcomes: !!predictedOutcomes?.length,
      hasRiskFactors: !!riskFactors,
      loading,
      error: error?.message
    });
  }, [monthlyForecast, predictedOutcomes, riskFactors, loading, error]);

  useEffect(() => {
    if (monthlyForecast?.length) {
      const hasValidProjections = monthlyForecast.some(m => 
        m.baseline || m.optimistic || m.pessimistic
      );
      if (!hasValidProjections) {
        console.error('Monthly forecast data is missing projection values');
      }
    }
    
    if (typeof pipelineCoverage !== 'number' || isNaN(pipelineCoverage)) {
      console.error('Invalid pipeline coverage value:', pipelineCoverage);
    }
  }, [monthlyForecast, pipelineCoverage]);

  const hasRequiredData = Boolean(
    !loading && 
    monthlyForecast?.length > 0 && 
    predictedOutcomes?.length > 0 && 
    typeof currentMRR === 'number' && 
    typeof currentARR === 'number' &&
    topMetrics?.revenuePerformance &&
    topMetrics?.pipelineHealth &&
    topMetrics?.quarterlyForecast &&
    topMetrics?.dealFlow
  );

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  if (loading) {
    return <SkeletonLoader />;
  }

  if (!hasRequiredData) {
    return <ErrorDisplay 
      message="Loading forecast data... Please wait while we gather your metrics."
      severity="info"
      showRefresh={false}
    />;
  }

  const getConfidenceColor = (probability) => {
    return probability >= 60 ? themeColors.status.success : themeColors.status.error
  }

  const tooltipFormatter = (value) => {
    if (typeof value === 'number') {
      return formatCurrency(value)
    }
    return value
  }

  const handleExport = async () => {
    await exportToPDF('forecast-dashboard', 'Revenue_Forecast')
  }

  const topDeals = [...predictedOutcomes]
    .sort((a, b) => b.value - a.value)
    .slice(0, 20);

  const formatPipelineCoverage = (coverage) => {
    if (typeof coverage !== 'number' || isNaN(coverage)) return '0.0x';
    return `${Math.max(0, coverage).toFixed(1)}x`;
  }

  const formatPercentage = (value) => {
    if (typeof value !== 'number' || isNaN(value)) return '0.0%';
    return `${Math.max(0, value).toFixed(1)}%`;
  }

  const getIconForRiskFactor = (factor) => {
    switch(factor) {
      case 'dealVelocity':
        return Speed;
      case 'dealSize':
        return TrendingUp;
      case 'revenueStability':
        return Timeline;
      case 'pipelineHealth':
        return Assessment;
      case 'growthMomentum':
        return ShowChart;
      default:
        return Assessment;
    }
  };

  const getRiskFactorDescription = (factor) => {
    switch(factor) {
      case 'dealVelocity':
        return 'Average time to close deals';
      case 'dealSize':
        return 'Change in average deal size';
      case 'revenueStability':
        return 'Consistency in revenue patterns';
      case 'pipelineHealth':
        return 'Overall pipeline quality score';
      case 'growthMomentum':
        return 'Rate of revenue growth';
      default:
        return '';
    }
  };

  const getEnhancedTooltip = (props) => {
    const { active, payload, label } = props;
    
    if (!active || !payload || !payload.length) {
      return null;
    }

    // Create ordered tooltip items with proper labels and colors
    const tooltipItems = [
      {
        label: 'Actual Revenue',
        value: payload.find(p => p.dataKey === 'actual')?.value || 0,
        formatType: 'currency',
        color: themeColors.chart.actual  // Blue
      },
      {
        label: 'Committed Pipeline',
        value: payload.find(p => p.dataKey === 'committed')?.value || 0,
        formatType: 'currency',
        color: themeColors.chart.committed  // Periwinkle
      },
      {
        label: 'Risk-Adjusted Pipeline',
        value: payload.find(p => p.dataKey === 'forecast')?.value || 0,
        formatType: 'currency',
        color: themeColors.chart.forecast  // Turquoise
      }
    ].filter(item => {
      const dataKey = {
        'Actual Revenue': 'actual',
        'Committed Pipeline': 'committed',
        'Risk-Adjusted Pipeline': 'forecast'
      }[item.label];
      return payload.some(p => p.dataKey === dataKey);
    });

    return (
      <CustomTooltip
        title={label}
        items={tooltipItems}
        formatType="currency"
      />
    );
  };

  return (
    <Box 
      id="forecast-dashboard"
      sx={{ 
        bgcolor: themeColors.background,
        p: 3 
      }}
    >
      <Container maxWidth="lg">
        {/* Replace separate export button with combined header */}
        <Box sx={{ mb: 4 }}>
          {/* Title and Export Button Row */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 2 
          }}>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 'bold', 
                color: themeColors.text.primary
              }}
            >
              AI-Powered Revenue Forecast
            </Typography>
          </Box>

          {/* Key Metrics Grid */}
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} md={3}>
              <RevenueMetric data={topMetrics.revenuePerformance} />
            </Grid>
            <Grid item xs={12} md={3}>
              <PipelineMetric data={topMetrics.pipelineHealth} />
            </Grid>
            <Grid item xs={12} md={3}>
              <ForecastMetric data={topMetrics.quarterlyForecast} />
            </Grid>
            <Grid item xs={12} md={3}>
              <DealFlowMetric data={topMetrics.dealFlow} />
            </Grid>
          </Grid>
        </Box>

        {/* AI Insights Alert */}
        <Alert 
          icon={<Brain />}
          severity="info"
          sx={{ mb: 3 }}
        >
          <Typography>
            AI Analysis: Based on current trends and market conditions, we predict a
            <Box component="span" sx={{ fontWeight: 'bold' }}> {topMetrics.quarterlyForecast.confidence}% probability </Box>
            of exceeding the baseline forecast by 15% or more.
          </Typography>
        </Alert>

        {/* Revenue Forecast Chart */}
        <Card sx={{ p: 3, mb: 6 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
            <Box>
              <Typography variant="h6" fontWeight="semibold">
                Revenue Forecast & Actuals
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Historical performance + Next 6 months
              </Typography>
            </Box>
            
            {/* Updated Legend */}
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              gap: 4
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ 
                  width: 12, 
                  height: 12, 
                  borderRadius: '50%', 
                  bgcolor: themeColors.chart.actual,
                  mr: 1 
                }} />
                <Typography variant="body2" color="text.secondary">Actual Revenue</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ 
                  width: 12, 
                  height: 12, 
                  borderRadius: '50%', 
                  bgcolor: themeColors.chart.committed,
                  mr: 1 
                }} />
                <Typography variant="body2" color="text.secondary">Committed Pipeline</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ 
                  width: 12, 
                  height: 12, 
                  borderRadius: '50%', 
                  bgcolor: themeColors.chart.forecast,
                  mr: 1 
                }} />
                <Typography variant="body2" color="text.secondary">Risk-Adjusted Pipeline</Typography>
              </Box>
            </Box>
          </Box>
          
          <div style={{ height: 400 }}>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart 
                data={monthlyForecast} 
                margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
              >
                <CartesianGrid 
                  strokeDasharray="3 3" 
                  stroke={themeColors.chart.grid} 
                />
                <XAxis 
                  dataKey="month" 
                  stroke={themeColors.text.secondary}
                  tick={{ fill: themeColors.text.secondary }}
                />
                <YAxis 
                  stroke={themeColors.text.secondary}
                  tick={{ fill: themeColors.text.secondary }}
                  tickFormatter={formatCurrency} 
                />
                <Tooltip 
                  content={getEnhancedTooltip}
                  isAnimationActive={false}
                />
                
                {/* Actual Revenue Line */}
                <Line 
                  type="monotone" 
                  dataKey="actual" 
                  name="Actual Revenue"
                  stroke={themeColors.chart.actual}
                  strokeWidth={2}
                  dot={{ fill: themeColors.chart.actual }}
                />
                
                {/* Committed Pipeline Line */}
                <Line 
                  type="monotone" 
                  dataKey="committed" 
                  name="Committed Pipeline"
                  stroke={themeColors.chart.committed}
                  strokeWidth={2}
                  dot={{ fill: themeColors.chart.committed }}
                  strokeDasharray="5 5"
                />
                
                {/* Risk-Adjusted Pipeline Line */}
                <Line 
                  type="monotone" 
                  dataKey="forecast" 
                  name="Risk-Adjusted Pipeline"
                  stroke={themeColors.chart.forecast}
                  strokeWidth={2}
                  dot={{ fill: themeColors.chart.forecast }}
                  strokeDasharray="5 5"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Card>

        {/* Predicted Deal Outcomes */}
        <Card sx={{ p: 2, mb: 6 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
            <Brain sx={{ fontSize: 24, color: '#36B5AD', mr: 2 }} />
            <Typography variant="h6" fontWeight="semibold">Top 20 AI-Predicted Deal Outcomes</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {topDeals.map((deal) => (
              <Box key={deal.id} sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e0e0e0', pb: 2 }}>
                <Box>
                  <Typography variant="body1" fontWeight="medium">{deal.name}</Typography>
                  <Typography variant="body2" color="text.secondary">Close Date: {deal.closeDate}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="body1" fontWeight="medium">{formatCurrency(deal.value)}</Typography>
                    <Typography variant="body2" color={getConfidenceColor(deal.probability)}>
                      {deal.probability}% Confidence
                    </Typography>
                  </Box>
                  {deal.probability >= 75 ? (
                    <CheckCircle2 sx={{ fontSize: 24, color: themeColors.status.success }} />
                  ) : deal.probability >= 50 ? (
                    <AlertCircle sx={{ fontSize: 24, color: themeColors.status.warning }} />
                  ) : (
                    <XCircle sx={{ fontSize: 24, color: themeColors.status.error }} />
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Card>

        {/* Risk Factors */}
        <Card sx={{ p: 2, mb: 8 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
            <FileWarning sx={{ fontSize: 24, color: themeColors.status.warning, mr: 2 }} />
            <Typography variant="h6" fontWeight="semibold">Risk Factor Analysis</Typography>
          </Box>
          <Grid container spacing={3}>
            {Object.entries(riskFactors).map(([key, value]) => (
              <Grid item xs={12} md={4} key={key}>
                <RiskFactorCard
                  title={key.charAt(0).toUpperCase() + key.slice(1)}
                  value={value}
                  icon={getIconForRiskFactor(key)}
                  description={getRiskFactorDescription(key)}
                />
              </Grid>
            ))}
          </Grid>
        </Card>

        {/* Deal Health Analytics */}
        <Card sx={{ p: 3, mb: 6 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
            <Brain sx={{ fontSize: 28, color: themeColors.status.info, mr: 2 }} />
            <Typography variant="h6" fontWeight="semibold">Deal Health Analytics</Typography>
          </Box>
          
          <Grid container spacing={4}>
            {/* Engagement Distribution */}
            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3, 
                  height: '100%',
                  bgcolor: 'background.default',
                  borderRadius: 2
                }}
              >
                <Typography variant="h6" sx={{ mb: 3 }}>Engagement Distribution</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  {[
                    { 
                      label: 'High Engagement', 
                      value: dealHealthMetrics.engagementHealth.highEngagement, 
                      color: themeColors.status.success,
                      icon: CheckCircle2
                    },
                    { 
                      label: 'Moderate Engagement', 
                      value: dealHealthMetrics.engagementHealth.moderateEngagement, 
                      color: themeColors.status.info,
                      icon: Timeline
                    },
                    { 
                      label: 'Low Engagement', 
                      value: dealHealthMetrics.engagementHealth.lowEngagement, 
                      color: themeColors.status.warning,
                      icon: AlertCircle
                    },
                    { 
                      label: 'At Risk', 
                      value: dealHealthMetrics.engagementHealth.atRisk, 
                      color: themeColors.status.error,
                      icon: XCircle
                    }
                  ].map((item) => (
                    <Box key={item.label}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        justifyContent: 'space-between', 
                        mb: 1 
                      }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <item.icon sx={{ fontSize: 20, color: item.color }} />
                          <Typography variant="body2">{item.label}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                          <Typography variant="body2" fontWeight="bold">
                            {item.value}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            deals
                          </Typography>
                        </Box>
                      </Box>
                      <LinearProgress
                        variant="determinate"
                        value={(item.value / forecastedDeals.totalCount) * 100}
                        sx={{ 
                          height: 8,
                          borderRadius: 4,
                          bgcolor: alpha(item.color, 0.12),
                          '& .MuiLinearProgress-bar': {
                            bgcolor: item.color,
                            borderRadius: 4
                          }
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Grid>

            {/* Deal Velocity */}
            <Grid item xs={12} md={6}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3, 
                  height: '100%',
                  bgcolor: 'background.default',
                  borderRadius: 2
                }}
              >
                <Typography variant="h6" sx={{ mb: 3 }}>Deal Velocity Metrics</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                  <Box>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                      Average Days to Close
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 2 }}>
                      <Typography variant="h3" fontWeight="bold">
                        {Math.round(dealHealthMetrics.velocityMetrics.averageDaysToClose)}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        days
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <LinearProgress 
                        variant="determinate" 
                        value={(dealHealthMetrics.velocityMetrics.averageDaysToClose / 90) * 100}
                        sx={{ 
                          height: 8,
                          borderRadius: 4,
                          bgcolor: alpha(themeColors.status.info, 0.12),
                          '& .MuiLinearProgress-bar': {
                            bgcolor: themeColors.status.info,
                            borderRadius: 4
                          }
                        }}
                      />
                      <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5, display: 'block' }}>
                        Compared to 90-day target
                      </Typography>
                    </Box>
                  </Box>

                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Paper 
                        sx={{ 
                          p: 2, 
                          bgcolor: alpha(themeColors.status.success, 0.08),
                          borderRadius: 2
                        }}
                      >
                        <Typography variant="body2" color={themeColors.status.success} sx={{ mb: 1 }}>
                          Above Target
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                          <Typography variant="h4" color={themeColors.status.success} fontWeight="bold">
                            {dealHealthMetrics.velocityMetrics.dealsAboveTarget}
                          </Typography>
                          <Typography variant="caption" color={themeColors.status.success}>
                            deals
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper 
                        sx={{ 
                          p: 2, 
                          bgcolor: alpha(themeColors.status.error, 0.08),
                          borderRadius: 2
                        }}
                      >
                        <Typography variant="body2" color={themeColors.status.error} sx={{ mb: 1 }}>
                          Below Target
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                          <Typography variant="h4" color={themeColors.status.error} fontWeight="bold">
                            {dealHealthMetrics.velocityMetrics.dealsBelowTarget}
                          </Typography>
                          <Typography variant="caption" color={themeColors.status.error}>
                            deals
                          </Typography>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>

            {/* Quality Indicators */}
            <Grid item xs={12}>
              <Paper 
                elevation={0}
                sx={{ 
                  p: 3,
                  bgcolor: 'background.default',
                  borderRadius: 2
                }}
              >
                <Typography variant="h6" sx={{ mb: 3 }}>Deal Quality Indicators</Typography>
                <Grid container spacing={3}>
                  {[
                    { 
                      label: 'Multi-threaded Deals',
                      value: dealHealthMetrics.qualityIndicators.multiThreaded,
                      total: forecastedDeals.totalCount,
                      color: themeColors.status.success,
                      icon: Group
                    },
                    {
                      label: 'Single-threaded Deals',
                      value: dealHealthMetrics.qualityIndicators.singleThreaded,
                      total: forecastedDeals.totalCount,
                      color: themeColors.status.warning,
                      icon: Person
                    },
                    {
                      label: 'Recent Activity',
                      value: dealHealthMetrics.qualityIndicators.recentActivity,
                      total: forecastedDeals.totalCount,
                      color: themeColors.status.info,
                      icon: Refresh
                    },
                    {
                      label: 'Stale Deals',
                      value: dealHealthMetrics.qualityIndicators.stale,
                      total: forecastedDeals.totalCount,
                      color: themeColors.status.error,
                      icon: AccessTime
                    }
                  ].map((indicator) => (
                    <Grid item xs={12} sm={6} md={3} key={indicator.label}>
                      <Box sx={{ 
                        textAlign: 'center',
                        p: 2,
                        bgcolor: alpha(indicator.color, 0.08),
                        borderRadius: 2,
                        height: '100%'
                      }}>
                        <indicator.icon 
                          sx={{ 
                            fontSize: 32, 
                            color: indicator.color,
                            mb: 1
                          }} 
                        />
                        <Typography 
                          variant="h4" 
                          sx={{ 
                            color: indicator.color,
                            mb: 0.5
                          }}
                        >
                          {Math.round((indicator.value / indicator.total) * 100)}%
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                          {indicator.label}
                        </Typography>
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            color: indicator.color,
                            display: 'block'
                          }}
                        >
                          {indicator.value} deals
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Card>

        {/* CTA Section */}
        <Paper
          elevation={0}
          sx={{ 
            p: 8,
            borderRadius: 2,
            bgcolor: themeColors.cta.background,
            border: `1px solid ${themeColors.cta.border}`,
            textAlign: 'center',
            mb: 3,
            backdropFilter: isDark ? 'blur(8px)' : 'none',
          }}
        >
          <Brain 
            sx={{ 
              color: themeColors.cta.text,
              fontSize: 48,
              mb: 4
            }} 
          />
          
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 700,
              fontSize: '1.875rem',
              color: themeColors.cta.text,
              mb: 2
            }}
          >
            Want More Accurate Revenue Predictions?
          </Typography>
          
          <Typography 
            variant="body1" 
            sx={{ 
              color: themeColors.cta.text,
              mb: 6,
              maxWidth: '600px',
              mx: 'auto',
              lineHeight: 1.5
            }}
          >
            Our AI-powered forecasting model can help you make data-driven decisions 
            with up to 95% accuracy. Schedule a demo to see how we can enhance your 
            revenue forecasting.
          </Typography>

          <Link 
            to="/contact/sales" 
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="contained"
              size="large"
              sx={{
                px: 8,
                py: 2,
                minWidth: '320px',
                fontSize: '1.1rem',
                bgcolor: themeColors.cta.button,
                color: isDark ? '#000000' : '#FFFFFF',
                '&:hover': {
                  bgcolor: themeColors.cta.buttonHover,
                },
                boxShadow: 'none',
                textTransform: 'uppercase',
                fontWeight: 600,
                letterSpacing: '0.5px'
              }}
            >
              Sign Up for a Demo
            </Button>
          </Link>
        </Paper>
      </Container>
    </Box>
  )
}

export default ForecastDashboard
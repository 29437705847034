import React, { useState } from 'react';
import { Box } from '@mui/material';
import HeroVC from '../../components/home/HeroVC';
import FeaturesVC from '../../components/home/FeaturesVC';
import HowItWorksVC from '../../components/home/HowItWorksVC';
import PricingVC from '../../components/home/PricingVC';
import ContactVC from '../../components/home/ContactVC';
import Layout from '../../components/home/Layout';
import SignupModal from '../../components/common/SignupModal';
import HomepageDemo from '../../pages/HomepageDemo';

const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenSignupModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseSignupModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <Box sx={{ 
        '& > *:not(:last-child)': { mb: 8 },
        pt: { xs: 10, sm: 12, md: 14 },
      }}>
        <HeroVC onOpenSignupModal={handleOpenSignupModal} />
        <Box sx={{ 
          px: { xs: 2, sm: 4, md: 6 },
          py: { xs: 4, sm: 6, md: 8 },
          bgcolor: 'background.default'
        }}>
          <HomepageDemo />
        </Box>
        <FeaturesVC />
        <HowItWorksVC />
        <PricingVC onOpenSignupModal={handleOpenSignupModal} />
        <ContactVC onOpenSignupModal={handleOpenSignupModal} />
        <SignupModal
          open={isModalOpen}
          onClose={handleCloseSignupModal}
        />
      </Box>
    </Layout>
  );
};

export default HomePage;
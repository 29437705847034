import React, { useState } from 'react';
import { Box, Typography, Grid, Tabs, Tab } from '@mui/material';
import { PipelineProvider, usePipeline } from '../../contexts/PipelineContext';
import PipelineFunnelChart from '../../components/sales/PipelineDashboard/PipelineFunnelChart';
import { SixMonthForecastProvider } from '../../contexts/6MonthForecastProvider';
import SixMonthForecastChart from '../../contexts/6MonthForecastChart';
import DealSizeScatterPlot from '../../components/sales/PipelineDashboard/DealSizeScatterPlot';
import TopDealsTable from '../../components/sales/PipelineDashboard/TopDealsTable';
import DealTypeDistribution from '../../components/sales/PipelineDashboard/DealTypeDistribution';
import PipelineProgressionAnalysis from '../../components/sales/PipelineDashboard/PipelineProgressionAnalysis';
import { useDashboardStyles } from '../../components/hooks/useDashboardStyles';
import SkeletonLoader from '../../utils/SkeletonLoader';

const PipelineDashboard = () => (
  <PipelineProvider>
    <SixMonthForecastProvider>
      <PipelineDashboardContent />
    </SixMonthForecastProvider>
  </PipelineProvider>
);

const PlaceholderChart = ({ title }) => {
  const { layout } = useDashboardStyles();
  return (
    <Box sx={layout.chartContainer}>
      <Typography>{title} Placeholder</Typography>
    </Box>
  );
};

const PipelineDashboardContent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { layout, typography, components } = useDashboardStyles();
  const { loading, error, pipelineData, forecastLines } = usePipeline();

  console.log('Pipeline Dashboard State:', { 
    activeTab,
    loading,
    hasError: !!error,
    hasPipelineData: !!pipelineData,
    hasForecastLines: !!forecastLines
  });

  return (
    <Box sx={layout.pageWrapper}>
      <Typography variant="h2" sx={typography.pageTitle}>
        Pipeline Analysis
      </Typography>
      
      <Tabs 
        value={activeTab} 
        onChange={(event, newValue) => {
          console.log('Tab changed to:', newValue);
          setActiveTab(newValue);
        }}
        sx={components.tabs.root}
      >
        <Tab label="Overview" sx={components.tabs.tab} />
        <Tab label="Details" sx={components.tabs.tab} />
      </Tabs>

      {activeTab === 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Pipeline Funnel</Typography>
              {loading ? <SkeletonLoader height={300} /> : <PipelineFunnelChart />}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>6 Month Forecast</Typography>
              {loading ? <SkeletonLoader height={300} /> : <SixMonthForecastChart />}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Deal Size Scatter Plot</Typography>
              {loading ? <SkeletonLoader height={300} /> : <DealSizeScatterPlot />}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Deal Type Distribution</Typography>
              {loading ? <SkeletonLoader height={300} /> : <DealTypeDistribution />}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Top Deals</Typography>
              {loading ? <SkeletonLoader height={300} /> : <TopDealsTable />}
            </Box>
          </Grid>
        </Grid>
      )}

      {activeTab === 1 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Deal Flow Between Stages</Typography>
              {loading ? <SkeletonLoader height={300} /> : <PipelineProgressionAnalysis />}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Progress Towards Sales Targets</Typography>
              <PlaceholderChart title="Progress Towards Sales Targets" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={layout.sectionWrapper}>
              <Typography variant="h6" sx={typography.sectionTitle}>Detailed Pipeline Metrics</Typography>
              <PlaceholderChart title="Detailed Pipeline Metrics" />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default PipelineDashboard;
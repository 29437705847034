import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButton = styled(MuiButton)(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.mode === 'dark' 
    ? '#34d399, #06b6d4'  // Dark mode gradient
    : '#059669, #0891b2'  // Light mode gradient
  })`,
  color: theme.palette.common.white,
  fontWeight: 600,
  textTransform: 'none',
  padding: '0.625rem 1.25rem',
  borderRadius: '0.5rem',
  fontSize: '0.875rem',
  lineHeight: 1.5,
  '&:hover': {
    background: `linear-gradient(to right, ${theme.palette.mode === 'dark'
      ? '#2fb344, #0596b4'  // Darker gradient for hover
      : '#047857, #0369a1'  // Darker gradient for hover
    })`,
  },
  '&.large': {
    padding: '0.75rem 1.5rem',
    fontSize: '1rem',
  },
  '&.fullWidth': {
    width: '100%',
  },
}));

export const Button = ({ 
  children, 
  size = 'medium',
  fullWidth = false,
  ...props 
}) => {
  return (
    <StyledButton 
      className={`${size === 'large' ? 'large' : ''} ${fullWidth ? 'fullWidth' : ''}`}
      {...props}
    >
      {children}
    </StyledButton>
  );
}; 